<template>
    <base-content>
        <!-- <div>
            <a-tag v-for="item in number_list" :key="item">{{ item }}</a-tag>
        </div>
        <a-input v-model:value="input" />
        <a-input-number v-model:value="target" :min="0" />
        <a-button @click="compute">计算</a-button> -->
        <a-list :data="todo_list"></a-list>
    </base-content>
</template>

<script>
export default {
    data() {
        return {
            input: '',
            target: 0.01,
            precision: 0,
            number_list: [],
            result_list: [],
            todo_list: [],
        };
    },
    watch: {
        input(value) {
            let t = value.split(' ');
            this.number_list = [];
            t.forEach((item) => {
                let n = Number(item);
                if (n > 0 && this.number_list.indexOf(n) == -1) this.number_list.push(n);
            });
        },
    },
    methods: {
        /**
         * 选择元素进行组合，最后返回一种最合适的组合
         * @param {[Number]} select_list 可选元素列表
         * @param {Number} count 选择的数量
         * @param {Number} target 目标值
         */
        chooes(select_list, count, target) {
            // 根据元素数量和选择数量生成一个数组，代表该位置的元素是否选中
            let t = [].concat(Array(count).fill(true), Array(select_list.length - count).fill(false));
            /**
             * 将代表位置的数组进行转换，规则如下：
             * 1.先找到第一个[···true,false···]，更改为[···false,true···]
             * 2.将第一步中更改位置前的所有true挪到最左边，如[f,f,t,t,f,[第一步]···]，改为[t,t,f,f,f,[第一步]···]
             * 按照此规则可以将所有组合排列完，但不能超出范围使用，否则会排列出错
             * 比如说C5/3共有10种排列方式，前9次调用该方法返回结果是正确的，第10次便会出错
             */
            let changeT = (t) => {
                let i = 0;
                for (; i < t.length - 1; i++) {
                    if (t[i] && !t[i + 1]) break;
                }
                [t[i], t[i + 1]] = [false, true];
                let c = 0;
                for (let j = 0; j < i; j++) {
                    if (t[j]) c++;
                }
                for (let j = 0; j < i; j++) {
                    t[j] = j < c;
                }
                return [].concat(t);
            };
            // 计算共有多少种组合
            let total = 1;
            for (let i = 1; i <= count; i++) {
                total = (total * (select_list.length - i + 1)) / i;
            }
            // 所有的组合方式，[每个元素的状态列表t]
            let combo = [[].concat(t)];
            for (let i = 1; i < total; i++) {
                t = changeT(t);
                combo.push(t);
            }
            let best_combo = [];
            let best_number = 0;
            for (let item in combo) {
                item = combo[item];
                let number = 0;
                for (let i = 0; i < select_list.length; i++) {
                    if (item[i]) number += select_list[i];
                }
                // console.log(item, number);
                if (number >= target && (best_number == 0 || number < best_number)) {
                    best_number = number;
                    let number_list = [];
                    for (let i = 0; i < select_list.length; i++) {
                        if (item[i]) number_list.push(select_list[i]);
                    }
                    best_combo = number_list;
                }
                if (best_number - target <= 1) {
                    return best_combo;
                }
            }
            return best_combo;
        },
        compute() {
            let number_list = this.number_list;
            let target = this.target;
            let precision = this.precision;
            number_list.sort((a, b) => {
                return b - a;
            });
            // 系数列表，一个二维数组，第一维度代表不同的用于组合的数，第二维度代表每一位共可以使用哪些系数，
            let coefficient_list = [];
            let total_number_list = [];
            for (let i = 0; i < number_list.length; i++) {
                coefficient_list[i] = [];
                let number = number_list[i];
                let max_multiple = parseInt(target / number) + 1;
                for (let coefficient = 0; coefficient <= max_multiple; coefficient++) {
                    let value = number * coefficient;
                    if (value == 0 || total_number_list.indexOf(value) == -1) {
                        total_number_list.push(value);
                        coefficient_list[i].push(coefficient);
                    }
                }
            }
            // 所有的组合数量，后续将通过组合的序号，计算每一位系数在系数列表种的索引
            let count = 1;
            coefficient_list.forEach((item) => {
                count = count * item.length;
            });
            let total_index_list = [];
            let product_list = [];
            for (let i = 0; i < count; i++) {
                let index_list = Array(number_list.length).fill(0);
                let index = i;
                let product = 0;
                // 计算每一位系数的索引
                for (let j = number_list.length - 1; j >= 0; j--) {
                    // 计算j位的系数索引
                    index_list[j] = index % coefficient_list[j].length;
                    // 计算截止当前的乘积
                    product = product + number_list[j] * coefficient_list[j][index_list[j]];
                    // 截止此时，如果计算结果已经大于目标值，其他位置零即可，可跳出循环，
                    if (product >= target) break;
                    // 准备用于下一次循环计算j-1位的索引
                    index = parseInt(index / coefficient_list[j].length);
                    // 如果下一位的系数索引已经是0，说明再往后的也都是0，无需再进行计算，跳出循环
                    if (index == 0) break;
                }
                // 如果本种组合方式的结果大于目标值，则添加到结果列表里
                if (product >= target) {
                    if (product_list.indexOf(product) == -1) {
                        product_list.push(product);
                        total_index_list.push(index_list);
                    }
                    if (product - target <= precision) break;
                }
            }
            let combo_list = [];
            total_index_list.forEach((item) => {
                let product = 0;
                let combo = {};
                for (let i = 0; i < item.length; i++) {
                    product = product + number_list[i] * coefficient_list[i][item[i]];
                    combo[`_${number_list[i]}`] = coefficient_list[i][item[i]];
                }
                combo_list.push({ product, combo });
            });
            combo_list.sort((a, b) => {
                return a.product - b.product;
            });
            console.log(combo_list);
        },
    },
};
</script>

<style></style>
